/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f6f7;
}
* {
  box-sizing: border-box;
}

body.modalOpen #root {
  transition: 0.2s ease-in-out all;
  filter: blur(1.5px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}
.disabled {
  cursor: not-allowed;
}

.ellispis {
  text-overflow: ellipsis;
}

.flex-1 {
  flex: 1;
}

.shadow-1 {
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 12%), 0 1px 2px hsla(0, 0%, 0%, 24%);
}
.shadow-2 {
  box-shadow: 0 3px 6px hsla(0, 0%, 0%, 15%), 0 2px 4px hsla(0, 0%, 0%, 12%);
}
.shadow-3 {
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 15%), 0 3px 6px hsla(0, 0%, 0%, 10%);
}

.edit-button:hover {
  cursor: pointer;
}

.create-button {
  background: #5d21d2;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 #c8b6e2;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  padding: 17px 32px 16px;
  cursor: pointer;
} */

.ant-row {
  display: block!;
}

.demo-infinite-container.companies-list {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 35px;
  height: 420px;
}

.demo-infinite-container.users-list {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 10px;
  height: 500px;
}

.ant-list-item {
  padding: 10px 0 !important;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.code-box-demo .ant-select {
  margin: 0 8px 10px 0;
}

.ant-row-rtl .code-box-demo .ant-select {
  margin: 0 0 10px 8px;
}

#components-select-demo-search-box .code-box-demo .ant-select {
  margin: 0;
}

.site-page-header-ghost-wrapper {
  background-color: #f5f5f5;
  padding: 24px;
}

.ant-page-header-heading-extra {
  display: flex;
}

.site-page-header {
  border: 1px solid rgb(235, 237, 240);
}

.description-list-processes {
  margin-right: 13px;
}

.title-header-process {
  font-size: 12px;
  color: #9a9c9e;
}

.value-header-process {
  font-size: 16px;
  font-weight: 700;
}

.submit-button {
  font-weight: 800 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  padding: 8px 16px !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  background-color: white !important;
  color: #5d21d2 !important;
  margin-top: 20px;
  border: 1px solid #5d21d2 !important;
}

.ant-col-offset-8 {
  margin: 0 auto !important;
  width: 100%;
}

.ant-form label {
  width: 100%;
}

.radio-currency {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 36px !important;
  width: 80px;
  padding: 8px 16px !important;
  border-radius: 4px !important;
}

.ant-form-item-label > label {
  line-height: 18px !important;
  color: #48465e !important;
}

ul {
  padding-inline-start: 0px !important;
}

.ant-radio-button-wrapper-checked {
  background-color: rgb(93, 33, 210) !important;
}

.ant-select-selector {
  height: 36px !important;
  border-radius: 3px !important;
}

label .ant-select-single .ant-select-item-option-content {
  font-size: 12px !important;
}

.form-container {
  width: 710px !important;
  margin: 0 auto;
  box-shadow: rgb(4 2 34 / 12%) 0px 5px 8px -1px,
    rgb(4 2 34 / 5%) 0px 2px 4px -1px;
  border-radius: 10px;
  padding: 40px;
}

.page-content-container {
  margin: 20px 0;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.display-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.ao-name {
  display: flex;
  width: 49%;
  padding-right: 5px;
  justify-content: space-between;
}

.ant-radio-inner {
  border-color: rgb(48, 28, 107) !important;
}

.ant-radio-inner:after {
  background-color: rgb(48, 28, 107) !important;
}

footer {
  text-align: center;
  background-color: #efe2ff;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(245, 247, 250);
  height: 45px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  color: rgb(132 133 135);
  font-weight: 300;
  font-size: 12px;
  padding-right: 20px;
}

/* footer {

    text-align: center;
    background-color: #ECEDEE;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    height: 50px;

}  */

.link-footer {
  display: contents;
  font-weight: 600;
}
